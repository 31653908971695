@media (max-height: 500px) {
  .payment-header-cta {
    display: none;
  }
}

@media (min-height: 501px) {
  .payment-header-cta-mobile {
    display: none;
  }

  .payment-header-cta-mobile-span {
    font-size: 20px;
  }
}

@media (max-height: 305px) {
  .support-me-cta {
    display: none;
  }
}

@media (max-height: 300px) {
  .payment-header-cta-mobile {
    display: none;
  }
}

@media (max-height: 400px) {
  .support-me-cta {
    display: none;
  }
}

@media (max-height: 200px) {
  .payment-header-cta-mobile {
    display: none;
  }

  .support-div {
    position: fixed !important;
    height: 100% !important;
  }

  .arrow {
    display: none;
  }

  .support-text {
    display: none;
  }
}

@media (min-height: 200px) {
  .support-text-mobile {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1000px) { 
  .arrow {
    display: none;
  }
}

.payment-header-cta-mobile {
  font-size: 20px;
}

.payment-header-cta-mobile-span {
  font-size: 20px;
}

.hero-image {
  background-image: url(./images/book_cover.jpg);
  background-size: 50vh;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  margin: auto 0;
}

.arrow {
  background-image: url(./images/icon_arrow.png);
  background-size: contain;
  width: 30px;
  height: 16px;
  background-repeat: no-repeat;
  margin-top: 10.75vh;
  position: absolute;
  right: 40px;
}

.kill-row {
  margin-left: 0;
  margin-right: 0;
}

.facebook-icon {
  background-image: url(./images/facebook.png);
  background-size: contain;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  margin-right: 10px;
  cursor:pointer;
  z-index: 100;
}

.messenger-icon {
  background-image: url(./images/messenger.png);
  background-size: contain;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  margin-right: 10px;
  cursor:pointer;
  z-index: 100;
}

.twitter-icon {
  margin-top: 2px;
  background-image: url(./images/twitter.png);
  background-size: contain;
  width: 13px;
  height: 13px;
  background-repeat: no-repeat;
  margin-right: 5px;
  cursor:pointer;
  z-index: 100;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.crux-modal-backdrop {
  opacity: .8 !important;
}

.close {
  opacity: 1 !important;
}

.support-me-cta {
  font-size: 15px;
}

.terms-privacy-link {
  color: black;
  text-decoration: underline;
}

.rockentry-logo {
  background-image: url(./images/rockentryLogo.png);
  background-size: 100px;
  height: 50px;
  width: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 30px;
  left: 50px;
  z-index: 100;
}

.support-div:hover {
  background-color: #13C87D;
} 