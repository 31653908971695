@media (max-height: 250px) {
  .numbers-button-wrapper {
    margin-top:20px;
  }
}

.numbers-button-active {
    background-color: #37D996;
}

input[type='number']:focus { 
  background-color: #37D996;
}

input::-moz-placeholder:focus {
  opacity: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}