.modal-header .close {
    color: white;
}

.thank-you-modal-footer{
  padding: 0;
}

.thank-you-modal-body {
  font-weight: 500;
  font-size: 21px;
  font-family: 'Avenir';
  padding: 30px;
}

.facebook-icon-thank-you {
    background-image: url(./images/facebook-ty.png);
    background-size: 15px;
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    margin-right: 10px;
    padding-right: 30px;
    cursor:pointer;
    z-index: 100;
    margin: auto 0;
  }
  
  .messenger-icon-thank-you {
    background-image: url(./images/messenger-ty.png);
    background-size: 33px;
    width: 56px;
    height: 35px;
    background-repeat: no-repeat;
    margin-right: 10px;
    padding-right: 30px;
    cursor:pointer;
    z-index: 100;
    margin: auto 0;
  }
  
  .twitter-icon-thank-you {
    margin-top: 2px;
    background-image: url(./images/twitter-ty.png);
    background-size: 37px;
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    margin-right: 5px;
    padding-right: 30px;
    cursor:pointer;
    z-index: 100;
    margin: auto 0;
  }