.modal-header {
    background-color: black;
    color: white;
}

.modal-title {
    padding: 5px;
    font-size: 24px;
    font-family: 'Avenir';
}

.checkout-modal-body {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Avenir';
    padding: 30px;
}

.form-control {
    height: 45px;
    border-radius: 3px;
    border-color: #DDDDDD;
    margin-bottom: 17px;
}

.form-group {
    padding-top: 20px;
}

.visa-icon {
    background-image: url(./images/visa.png);
    background-size: contain;
    width: 49px;
    height: 30px;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.mastercard-icon {
    background-image: url(./images/master.png);
    background-size: contain;
    width: 49px;
    height: 30px;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.amex-icon {
    background-image: url(./images/amex.png);
    background-size: contain;
    width: 49px;
    height: 30px;
    background-repeat: no-repeat;
    margin-right: 5px;
}

.discover-icon {
    background-image: url(./images/discover.png);
    background-size: contain;
    width: 49px;
    height: 30px;
    background-repeat: no-repeat;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 0px auto;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.payment-make-section {
  border: 1px solid #E1E1E1;
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 500px) {
  .credit-or-debit {
    font-size: 11px;
  }

  .visa-icon {
    width: 45px;
    margin-right: 2px;
  }

  .mastercard-icon {
    width: 45px;
    margin-right: 2px;
  }

  .amex-icon {
    width: 45px;
    margin-right: 2px;
  }

  .discover-icon {
    width: 45px;
  }
}

@media (max-width: 360px) {
  .credit-card-row-wrapper {
    flex-direction: column;
  }
  
  .pre-order-modal-title {
    font-size: 20px;
  }
}